import React from 'react';
import './sean.css';
import Button from '@mui/material/Button';
import Box from '@material-ui/core/Box';
import logo from './img/seanLogo.jpg';
import phoneIcon from './img/calling.png';
import discordIcon from './img/ping.png';
import githubIcon from './img/5head.png';
import steamIcon from './img/game.gif';
import credit from './img/credit.gif';
import Alert from '@mui/material/Alert';

 
const Sean = () => {
   const [open, setOpen] = React.useState(false);
   return (
      <div className="sMain">
         {open && <div className="notfication">
            <Alert severity="success" variant="filled" onClose={() => {setOpen(false)}}>Copied!</Alert>
         </div>}
            <img className="logo" src={logo} alt="logo"/>
            <p className="sName">Sean</p>
            <Box m={2} >
               <Button variant="outlined" href="tel:07307466633" style={{
                  borderColor: "#000000", 
                  borderRadius: 35,
                  minWidth: "250px",
                  color: "#000000", 
                  border: '2px solid',
               }}><img className="icon" src={phoneIcon} alt="icon"/><div className="buttonLabel">Phone</div>
               </Button>
            </Box>
            <Box m={2}>
               <Button onClick={() => navigator.clipboard.writeText('Tesco_Zimbabwe#0001') && setOpen(true)} variant="outlined" style={{
                  borderColor: "#000000", 
                  borderRadius: 35,
                  minWidth: "250px",
                  color: "#000000", 
                  border: '2px solid',
               }}><img className="icon" src={discordIcon} alt="icon"/><div className="buttonLabel">Discord</div>
               </Button>
            </Box>
            <Box m={2}>
               <Button variant="outlined" href="https://github.com/seanplacidostewart" style={{
                  borderColor: "#000000", 
                  borderRadius: 35,
                  minWidth: "250px",
                  color: "#000000", 
                  border: '2px solid',
               }}><img className="icon" src={githubIcon} alt="icon"/><div className="buttonLabel">GitHub</div>
               </Button>
            </Box>
            <Box m={2}>
               <Button variant="outlined" href="https://steamcommunity.com/id/tesco_zimbabwe" style={{
                  borderColor: "#000000", 
                  borderRadius: 35,
                  minWidth: "250px",
                  color: "#000000", 
                  border: '2px solid',
               }}><img className="icon" src={steamIcon} alt="icon"/><div className="buttonLabel">Steam</div>
               </Button>
            </Box>
            <Box m={2}>
               <Button variant="outlined" href="/sean/payment" style={{
                  borderColor: "#000000", 
                  borderRadius: 35,
                  minWidth: "250px",
                  color: "#000000", 
                  border: '2px solid',
               }}><img className="icon" src={credit} alt="icon"/><div className="buttonLabel">You owe me cash?</div>
               </Button>
            </Box>
      </div>
   );
}
 
export default Sean;