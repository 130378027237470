import React from 'react';
import './sean.css';
import Button from '@mui/material/Button';
import Box from '@material-ui/core/Box';
import logo from './img/seanLogo.jpg';
import discordIcon from './img/ping.png';
import githubIcon from './img/5head.png';
import credit from './img/credit.gif';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@material-ui/icons/Payment';
import { blue } from '@mui/material/colors';
import PropTypes from 'prop-types';
import DialogContentText from '@mui/material/DialogContentText';
import Alert from '@mui/material/Alert';


 
const Sean = () => {
   const CryptoMethod = ['Coinbase Username', 'BTC - SegWit', 'BTC - Legacy', 'ETH', 'USDC', 'DOGE', 'COMP', 'SUSHI'];

   function SimpleDialog(props) {
   const { onClose, selectedValue, open } = props;

   const handleClose = () => {
      onClose(selectedValue);
   };

   const handleListItemClick = (value) => {
      switch (value) {
         case "Coinbase Username":
            navigator.clipboard.writeText("@seanplacidostewart")
            setNotOpen(true)
            value = "@seanplacidostewart"
            break;
         case "BTC - SegWit":
            navigator.clipboard.writeText("bc1qz0apczrwm40p9lmdvm3ygn7pez2ytx6r5z9vx8")
            setNotOpen(true)
            value = "bc1qz0apczrwm40p9lmdvm3ygn7pez2ytx6r5z9vx8"
            break;
         case "BTC - Legacy":
            navigator.clipboard.writeText("1JnX9R7MHVhPJPGfrUZenqQL4qsqEb1hEG")
            setNotOpen(true)
            value = "1JnX9R7MHVhPJPGfrUZenqQL4qsqEb1hEG"
            break;
         case "ETH":
            navigator.clipboard.writeText("0x0E187a7aa45De8CC78514aB0320cdB8Cc44c4D10")
            setNotOpen(true)
            value = "0x0E187a7aa45De8CC78514aB0320cdB8Cc44c4D10"
            break;
         case "USDC":
            navigator.clipboard.writeText("0x0E187a7aa45De8CC78514aB0320cdB8Cc44c4D10")
            setNotOpen(true)
            value = "0x0E187a7aa45De8CC78514aB0320cdB8Cc44c4D10"
            break;
          case "DOGE":
            navigator.clipboard.writeText("DPuXL7Cd1hQ3KonVLgYjBiUeMKK1QRFPb2")
            setNotOpen(true)
            value = "DPuXL7Cd1hQ3KonVLgYjBiUeMKK1QRFPb2"
            break;
          case "COMP":
            navigator.clipboard.writeText("0x0E187a7aa45De8CC78514aB0320cdB8Cc44c4D10")
            setNotOpen(true)
            value = "0x0E187a7aa45De8CC78514aB0320cdB8Cc44c4D10"
            break;
          case "SUSHI":
            navigator.clipboard.writeText("0x0E187a7aa45De8CC78514aB0320cdB8Cc44c4D10")
            setNotOpen(true)
            value = "0x0E187a7aa45De8CC78514aB0320cdB8Cc44c4D10"
            break;
         default:
            navigator.clipboard.writeText("@seanplacidostewart")
            value = "@seanplacidostewart"
            break;
      }
      onClose(value);
   };

   return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Choose Crypto Method</DialogTitle>
        <List sx={{ pt: 0 }}>
          {CryptoMethod.map((btcMethodResult) => (
            <ListItem button onClick={() => handleListItemClick(btcMethodResult)} key={btcMethodResult}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={btcMethodResult} />
            </ListItem>
          ))}
         <DialogContentText textAlign="center" paddingTop="10px">Powered by Coinbase</DialogContentText>
      </List>
    </Dialog>
    );
  }

  SimpleDialog.propTypes = {
   onClose: PropTypes.func.isRequired,
   open: PropTypes.bool.isRequired,
   selectedValue: PropTypes.string.isRequired,
 };

 const [open, setOpen] = React.useState(false);
 const [notficationOpen, setNotOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(CryptoMethod[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  
  
   return (
      <div className="sMain">
         {notficationOpen && <div className="notfication">
            <Alert severity="success" variant="filled" onClose={() => {setNotOpen(false)}}>Copied!</Alert>
         </div>}
            <img className="logo" src={logo} alt="logo"/>
            <p className="sName">Sean</p>
            <Box m={2} >
               <Button variant="outlined" href="https://monzo.me/seanplacidostewart" style={{
                  borderColor: "#000000", 
                  borderRadius: 35,
                  minWidth: "250px",
                  color: "#000000", 
                  border: '2px solid',
               }}><img className="icon" src={credit} alt="icon"/><div className="buttonLabel">Debit Card</div>
               </Button>
            </Box>
            <Box m={2}>
               <Button variant="outlined" href="https://www.paypal.com/paypalme/seanplacidostewart?locale.x=en_GB" style={{
                  borderColor: "#000000", 
                  borderRadius: 35,
                  minWidth: "250px",
                  color: "#000000", 
                  border: '2px solid',
               }}><img className="icon" src={discordIcon} alt="icon"/><div className="buttonLabel">PayPal</div>
               </Button>
            </Box>
            <Box m={2}>
               <Button variant="outlined" onClick={handleClickOpen} style={{
                  borderColor: "#000000", 
                  borderRadius: 35,
                  minWidth: "250px",
                  color: "#000000", 
                  border: '2px solid',
               }}><img className="icon" src={githubIcon} alt="icon"/><div className="buttonLabel">Crypto</div>
               </Button>
               <SimpleDialog
               selectedValue={selectedValue}
               open={open}
               onClose={handleClose}
               />
            </Box>
      </div>
   );
}
 
export default Sean;