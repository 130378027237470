import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
 
import sean from './components/sean';
import seanPayment from './components/seanPayment'
import lauren from './components/lauren';
import Error from './components/error';
 
class App extends Component {
  render() {
    return (      
       <BrowserRouter>
        <div>
            <Switch>
             <Route path="/sean" component={sean} exact/>
             <Route path="/sean/payment" component={seanPayment} exact/>
             <Route path="/lauren" component={lauren}/>
            <Route component={Error}/>
           </Switch>
        </div> 
      </BrowserRouter>
    );
  }
}
 
export default App;