import React from 'react';
import './lauren.css';
import Button from '@mui/material/Button';
import Box from '@material-ui/core/Box';
import logo from './img/laurenLogo.png';
import phoneIcon from './img/calling.png';
import discordIcon from './img/ping.png';
import snapchatIcon from './img/photo.gif';
import linkedinIcon from './img/business.png';
import githubIcon from './img/5head.png';
import steamIcon from './img/game.gif';
import Alert from '@mui/material/Alert';

const Lauren = () => {
   const [open, setOpen] = React.useState(false);
   return (
      <div className="lMain">
         {open && <div className="notfication">
            <Alert severity="success" variant="filled" onClose={() => {setOpen(false)}}>Copied!</Alert>
         </div>}
            <img className="logo" src={logo} alt="logo"/>
            <p className="lName">Lauren</p>
            <Box m={2} >
               <Button variant="outlined" href="tel:07366331058" style={{
                  borderColor: "#000000", 
                  borderRadius: 35,
                  minWidth: "250px",
                  color: "#000000", 
                  border: '2px solid',
               }}><img className="icon" src={phoneIcon} alt="icon"/><div className="buttonLabel">Phone</div>
               </Button>
            </Box>
            <Box m={2}>
               <Button onClick={() => navigator.clipboard.writeText('MiniChickens#1149') && setOpen(true)} variant="outlined" style={{
                  borderColor: "#000000", 
                  borderRadius: 35,
                  minWidth: "250px",
                  color: "#000000", 
                  border: '2px solid',
               }}><img className="icon" src={discordIcon} alt="icon"/><div className="buttonLabel">Discord</div>
               </Button>
            </Box>
            <Box m={2}>
               <Button variant="outlined" href="https://www.instagram.com/laurenlphotos/" style={{
                  borderColor: "#000000", 
                  borderRadius: 35,
                  minWidth: "250px",
                  color: "#000000", 
                  border: '2px solid',
               }}><img className="icon" src={snapchatIcon} alt="icon"/><div className="buttonLabel">Instagram</div>
               </Button>
            </Box>
            <Box m={2}>
               <Button variant="outlined" href="https://uk.linkedin.com/in/lauren-l1236?trk=people-guest_people_search-card" style={{
                  borderColor: "#000000", 
                  borderRadius: 35,
                  minWidth: "250px",
                  color: "#000000", 
                  border: '2px solid',
               }}><img className="icon" src={linkedinIcon} alt="icon"/><div className="buttonLabel">LinkedIn</div>
               </Button>
            </Box>
            <Box m={2}>
               <Button variant="outlined" href="https://github.com/LaurenNLawson" style={{
                  borderColor: "#000000", 
                  borderRadius: 35,
                  minWidth: "250px",
                  color: "#000000", 
                  border: '2px solid',
               }}><img className="icon" src={githubIcon} alt="icon"/><div className="buttonLabel">GitHub</div>
               </Button>
            </Box>
            <Box m={2}>
               <Button variant="outlined" href="https://steamcommunity.com/id/chickenmini/" style={{
                  borderColor: "#000000", 
                  borderRadius: 35,
                  minWidth: "250px",
                  color: "#000000", 
                  border: '2px solid',
               }}><img className="icon" src={steamIcon} alt="icon"/><div className="buttonLabel">Steam</div>
               </Button>
            </Box>
      </div>
   );
}
 
export default Lauren; /* I love you Sean, from Lauren <3 */