import React from 'react';
import modCheck from './modcheck.gif'
import './error.css';
 
const Error = () => {
    return (
        <div className="App">
            <img className="modCheck" src={modCheck} alt="modCheck"/>
            <p className="errorText">4 OMEGALUL 4</p>
        </div>
    );
}
 
export default Error;